<template>
  <b-container
    id="company-details-slide"
    class="container-fluid"
  >
    <slide-heading
      :heading="currentSlide.heading"
    />

    <template v-if="loaded">
      <div class="slide-description mb-5 justify-content-center">
        <div v-html="slideDescription" />
      </div>

      <company-details />

      <div>
        <p class="my-5">
          If all of the above information looks correct, click <strong>Looks Good</strong> to complete
          the CTA Guide and return  to your Company page. Otherwise, click <strong>Make Changes
          </strong> to file a Beneficial Ownership Information Report.
        </p>

        <div class="buttons d-flex justify-content-center">
          <b-button
            class="btn-outline-primary"
            variant="btn-outline-primary"
            aria-label="looks good button"
            @click="completeAndRedirectToStageline"
          >
            Looks Good!
          </b-button>
          <b-button
            class="btn-primary"
            variant="btn-primary"
            aria-label="make changes button"
            @click="makeChanges"
          >
            Make Changes
          </b-button>
        </div>
      </div>
    </template>

    <ct-centered-spinner v-else>
      {{ loadingText }}
    </ct-centered-spinner>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as DOMPurify from 'dompurify'

export default {
  name: 'CompanyDetailsSlide',
  components: {
    CompanyDetails:            () => import('@/components/CompanyDetails/CompanyDetails'),
    CtCenteredSpinner:         () => import('@/components/shared/CtCenteredSpinner'),
    SlideHeading:              () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slideProducts: Array,
  },
  data() {
    return {
      loaded: true,
      loadingText: 'Loading Company Details...',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentSlide',
      'currentPeriod',
      'company',
      'periods',
    ]),
    slideDescription() {
      return DOMPurify.sanitize(this.currentSlide.description)
    },
  },
  async mounted() {
  },
  methods: {
    ...mapActions('stageline', [
      'completePeriod',
    ]),
    async completeAndRedirectToStageline() {
      this.loadingText = 'Submitting...'
      this.loaded = false
      await this.completePeriod(this.currentPeriod.id)

      if (this.periods.length > 1) {
        // Refresh will reload stageline with the start a business period
        location.reload()
      } else {
        await this.$router.push({ name: 'dashpanel' })
      }
    },
    async makeChanges() {

      // TODO File BOI

      this.$emit('next-slide')
    },
  },
}
</script>

<style scoped lang="scss">
#company-details-slide {
  .buttons {
    button {
      margin: 0 0.3125em;
    }
  }
}
</style>
